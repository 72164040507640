<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'AnLiInfo',
  mixins: [],
  mounted() {
  },
  methods: {},
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
